<template>
  <div class="global-container">
    <b-overlay :show="isLoading" rounded="sm">
      <div class="card login-form">
        <div class="card-body">
          <div class="card-text">
            <div v-if="showError" class="alert alert-danger alert-dismissible fade show" role="alert">Incorrect username or password.</div>
            <form @submit.prevent="submit">
              <div class="form-group text-left">
                <label for="login">Login</label>
                <input
                  id="login"
                  type="text"
                  class="form-control form-control-sm"
                  v-model="login"
                >
              </div>
              <div class="form-group text-left">
                <label for="password">Password</label>
                <input
                  id="password"
                  type="password"
                  class="form-control form-control-sm"
                  v-model="password"
                >
              </div>
              <button type="submit" class="btn btn-primary btn-block">Sign in</button>
            </form>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
import store from "@/store";
import axios from "axios";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      isLoading: false,
      login: "",
      password: "",
      showError: false,
      signedVykazHodin: false,
    };
  },
  methods: {
    ...mapActions(["LogInUser", "getUserInfo", "checkFillReportsVykazHodin"]),

    async checkSignVykazHodinShowModal() {
      if (!store.getters.isKalendarPageAllowed) {
        return;
      }

      this.signedVykazHodin = await this.checkSignVykazHodin();
      if (_.isBoolean(this.signedVykazHodin) && !this.signedVykazHodin) {
        this.$bvModal.show('no-signed-vykaz-hodin-confirmation-modal');
        await this.$router.push("/");
      }
    },
    async checkFillReportsVykazHodinShowModal() {
      if (!store.getters.isKalendarPageAllowed) {
        return;
      }

      await this.checkFillReportsVykazHodin();
      if (_.isBoolean(this.$store.getters.isFilledReportsVykazHodin) && !this.$store.getters.isFilledReportsVykazHodin) {
        if (this.signedVykazHodin) {
          this.$bvModal.show('no-filled-vykaz-hodin-confirmation-modal');
        }
      }
    },
    async checkSignVykazHodin() {
      const url = `/user/checkSignVykazHodin`;
      const { data } = await axios.get(url, {});
      return data.data?.signed;
    },
    async submit() {
      try {
        this.showError = false;
        this.isLoading = true;
        const user = {
          login: this.login,
          password: this.password,
        }
        await this.LogInUser(user);
        await this.getUserInfo();
        await this.checkSignVykazHodinShowModal();
        await this.checkFillReportsVykazHodinShowModal();

        this.isLoading = false;
        await this.$router.push("/");
      } catch (error) {
        console.log(error)
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

label {
  padding: 12px 12px 0 8px;
  display: inline-block;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

input {
  margin: 5px;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  padding: 10px;
}

html,body {
  height: 100%;
}

.global-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

form {
  padding-top: 10px;
  font-size: 16px;
}

.card-title{ font-weight:300; }

.btn{
  font-size: 14px;
  margin-top:20px;
}


.login-form{
  width:330px;
  margin:20px;
}

.alert{
  margin-bottom:-10px;
  font-size: 13px;
  margin-top:20px;
}
</style>
